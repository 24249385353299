<template>
  <LayoutSuperadmin>
    <template v-slot:title>Members</template>
    <div class="p-md-5">
      <GoBackBtn>Back</GoBackBtn>
      <!-- General INfo -->
      <div class="row mt-5">
        <div class="col-12 col-md-6">
          <div class="d-flex">
            <div>
              <AppMediaViewer
                aspectRatio="1/1"
                width="60px"
                class="rounded-circle"
              ></AppMediaViewer>
            </div>
            <div class="ml-3">
              <AppTextLabeled label="SALES PERSONNEL">
                <h4>ECE AKMAN</h4>
              </AppTextLabeled>
            </div>
          </div>
          <div class="mt-4 d-flex">
            <AppTextLabeled label="Email">
              Lancesuarez@antelopebuilders.com
            </AppTextLabeled>

            <AppTextLabeled label="CONTACT NUMBER" class="ml-5">
              202-555-0195
            </AppTextLabeled>
          </div>
        </div>
        <!-- Total Number of Sales -->
        <div class="col-4 col-md-2">
          <AppTextLabeled label="Total Number of Sales">
            $ 24100
          </AppTextLabeled>
        </div>
        <!-- Completed Orders -->
        <div class="col-4 col-md-2">
          <AppTextLabeled label="Completed Orders"> 150 </AppTextLabeled>
        </div>
        <!-- ON GOING QUOTE SENT -->
        <div class="col-4 col-md-2">
          <AppTextLabeled label="ON GOING QUOTE SENT"> 4 </AppTextLabeled>
        </div>
      </div>

      <!--  -->
    </div>
  </LayoutSuperadmin>
</template>

<script>
import LayoutSuperadmin from '@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import GoBackBtn from '../../../../../components/Partial/GoBackBtn.vue';
import AppMediaViewer from '../../../../../shared/elements/AppMediaViewer.vue';
import AppTextLabeled from '../../../../../shared/elements/AppTextLabeled.vue';
export default {
  components: { LayoutSuperadmin, GoBackBtn, AppMediaViewer, AppTextLabeled },
  name: 'SuperMemberView',
};
</script>

<style lang="scss" scoped></style>
